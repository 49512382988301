import React from "react"
import Layout from "../components/Layout";
import styles from "../css/project-template.module.css";
import {graphql} from "gatsby";
import PageSection from "../components/PageSection";
import appStore from ".././images/app-store.png";
import googlePlay from ".././images/google-play.png";
import SEO from "../components/SEO"

const Project = ({data,location}) => {
    const {
        thumbnail,
        logo,
        productImage,
        title,
        description,
        playStoreUrl,
        appStoreUrl,
        sitePreview,
        date,
    } = data.markdownRemark.frontmatter;

    return (
        <Layout>
            <SEO title={title} description={description} pageImage={thumbnail} pathname={location.pathname}/>
            <div className={styles.headerContainer}>
                <img className={styles.image} src={thumbnail} alt={title}/>
            </div>
            <PageSection>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <img className={styles.logo} src={logo} alt={"logo project " + title}/>
                        {playStoreUrl !== null || "" ? <a href={playStoreUrl}>
                            <img
                                className={styles.store}
                                alt='Get it on Google Play'
                                src={googlePlay}
                            />
                        </a> : null
                        }
                        {appStoreUrl !== null || "" ? <a href={appStoreUrl}>
                            <img
                                className={styles.store}
                                alt='Available on the App Store'
                                src={appStore}
                            />
                        </a> : null
                        }
                        {sitePreview !== null || "" ? <a href={sitePreview}>Visit website</a> : null}
                    </div>
                    <div className={styles.project}>
                        <h1 className={styles.title}>{title}</h1>
                        <p>{description}</p>
                        <div className={styles.content} dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
                    </div>
                </div>
            </PageSection>
        </Layout>
    )
};

export const query = graphql`
    query($slug: String) {
        markdownRemark(fields:  {slug: {eq: $slug} }) {
            fields{
                slug
            }
            frontmatter {
                thumbnail
                logo
                productImage
                title
                description
                date
                playStoreUrl
                appStoreUrl
                sitePreview
                date
            }
            id
            html
        }
    }
`;

export default Project